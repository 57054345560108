import { ForwardedRef, forwardRef, useMemo, useRef, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
import { ReportTypes } from 'features/settings/data/reportTypes'
import { ModalProps } from 'types'

import { Column } from 'components/atoms/Column'
import { Modal, ModalFooter } from 'components/molecules'
import AntiCheatinMonitor from 'components/molecules/AssesmentPreviewModal/AntiCheatinMonitor'
import AutoMarkingProgress from 'components/molecules/AssesmentPreviewModal/AutoMarkingProgress'
import { AssesmentAnswerRatingView } from 'components/molecules/AssesmentPreviewModal/DownloadReport/AssesmentAnswerRatingView'
import AssessmentCompletedSection from 'components/molecules/AssesmentPreviewModal/DownloadReport/AssessmentCompletedSection'

import { ReportBanner, ReportFooter, ReportLogoHeader } from '../../sections'
import {
  AssessmentReportCandidateDetails,
  AssessmentReportCandidateDetailsDto,
} from '../../sections/ReportCandidateDetails/AssessmentReportCandidateDetails'

import { AssessmentSnapsPreview } from './AssessmentSnapsPreview'

import './style.scss'

type AssessmentReportPreviewModalProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType
  previewData: assessmentReportPreviewData | undefined
  dynamicFieldMapping: any
  changeTextSchema: any
  isMappingVariablesLoading?: boolean
  reportViewData: any
  markingValue: any
  handlePrint: () => void
  resetClientOption: () => void
  setIsDownloadBtnClicked: React.Dispatch<React.SetStateAction<boolean>>
}

export const AssessmentReportPreviewModal = forwardRef(
  (
    {
      candidateDetails,
      previewData,
      reportViewData,
      markingValue,
      setIsModalActive,
      isModalActive,
      handlePrint,
      resetClientOption,
      setIsDownloadBtnClicked,
    }: AssessmentReportPreviewModalProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const images = reportViewData.antiCheatingImageUrl
    const [isWebcamSnapsView, setIsWebcamSnapsView] = useState(false)

    const modalRef = useRef<HTMLDivElement>(null)
    const candidateReportDetails = useMemo(() => {
      const details: AssessmentReportCandidateDetailsDto = {
        name: candidateDetails.name,
        candidateNo: candidateDetails.candidateNo,
        email: candidateDetails.email,
        phoneNumber: candidateDetails.phone,
        avatarUrl: candidateDetails.avatarUrl,
        jobTitle: candidateDetails.jobTitle,
        consultantName: candidateDetails.consultantName,
      }
      return details
    }, [candidateDetails])

    return (
      <>
        <Modal
          headerTitle='Assessment Report Preview'
          isFooterShow={true}
          isActive={isModalActive}
          className='!z-[150]'
          onHeaderCloseButtonClick={() => setIsModalActive(false)}
          onOverlayClick={() => setIsModalActive(false)}
          primaryButtonProps={{
            style: { marginRight: '12px' },
          }}
          secondaryButtonProps={{
            style: { color: '#1F2937' },
          }}
          modalProps={{
            style: { width: '800px', height: '95vh' },
          }}
          footerProps={{
            style: {
              justifyContent: 'end',
              paddingLeft: '23px',
              paddingRight: '23px',
            },
          }}>
          <div
            ref={modalRef}
            className='relative flex flex-col p-0 overflow-x-hidden overflow-y-auto styled-scroll bg-Gray-50'>
            <Column className='bg-Gray-50 w-[794px] pointer-events-none'>
              <div ref={ref} className='w-full h-full p-0 m-0'>
                <div>
                  <div className='h-auto relative'>
                    <ReportLogoHeader
                      clientlogo={previewData?.clientLogoUrl}
                      hasClientLogo={previewData?.hasClientLogo}
                      hasCompanyLogo={previewData?.hasCompanyLogo}
                      type={ReportTypes.ASSESSMENT}
                    />
                    <ReportBanner type={ReportTypes.ASSESSMENT} />
                    <div className='m-3'>
                      <AssessmentReportCandidateDetails
                        details={candidateReportDetails}
                      />
                      <div className='h-auto pb-4'>
                        <AutoMarkingProgress
                          values={markingValue}
                          isReport={true}
                          totalMarks={
                            reportViewData?.assessmentAutoMarking?.totalMarks
                          }
                        />
                      </div>
                      <div className='h-auto pb-4'>
                        <AssessmentCompletedSection
                          reportViewData={reportViewData}
                        />
                      </div>
                      <div className='h-auto pb-4'>
                        <AntiCheatinMonitor
                          reportViewData={reportViewData}
                          isReport={true}
                          setIsWebcamSnapsView={setIsWebcamSnapsView}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='px-6 font-Medium text-base'>RESPONSES</div>
                <div className='px-6'>
                  {reportViewData.reportQuestion.map((item, idx) => (
                    <AssesmentAnswerRatingView
                      selectedTemplate={item}
                      key={idx}
                      isPrint={false}
                    />
                  ))}
                </div>
                <ReportFooter isFixed={true} />
              </div>
            </Column>
          </div>

          <ModalFooter className='py-4 bg-Gray-50'>
            <div className='flex justify-end flex-1'>
              <Button
                onClick={() => setIsModalActive(false)}
                textTheme='blue'
                theme='white'>
                Close
              </Button>

              <Button
                onClick={() => {
                  setIsDownloadBtnClicked(true)
                  handlePrint()
                  resetClientOption()
                }}
                iconLeft={
                  <i className='flex items-center ri-download-line text-small' />
                }
                className='ml-2'
                theme='cobalt'>
                Download
              </Button>
            </div>
          </ModalFooter>
        </Modal>

        {isWebcamSnapsView && (
          <AssessmentSnapsPreview
            isModalActive={isWebcamSnapsView}
            setIsModalActive={setIsWebcamSnapsView}
            candidateDetails={candidateDetails}
            previewData={previewData}
            images={images}
          />
        )}
      </>
    )
  },
)
