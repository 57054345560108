import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import clientLogoPlaceholder from 'assets/images/default-client-logo.svg'
import companyLogoPlaceholder from 'assets/images/default-company-logo.svg'
import { getAgency } from 'features/agencies/store/selectors'
import {
  getAssessmentReportSettings,
  getReportSettings,
} from 'features/settings'
import { ReportTypes } from 'features/settings/data/reportTypes'

import { RowWithAlignment } from 'components/atoms/Row'

type ReportLogoHeaderProps = {
  clientlogo?: string
  type?: string
  hasClientLogo?: boolean
  hasCompanyLogo?: boolean
  isSampleView?: boolean
}

export const ReportLogoHeader = ({
  clientlogo,
  hasClientLogo = false,
  hasCompanyLogo = false,
  isSampleView = false,
  type = ReportTypes.REFERENCE,
}: ReportLogoHeaderProps) => {
  const { agency } = useSelector(getAgency)
  const referenceSettings = useSelector(getReportSettings)
  const assessmentSettings = useSelector(getAssessmentReportSettings)

  const { companyLogo } = useMemo(() => {
    const isAssessment = type === ReportTypes.ASSESSMENT
    const settings = isAssessment ? assessmentSettings : referenceSettings

    return {
      companyLogo: settings.companyLogo,
    }
  }, [type, referenceSettings, assessmentSettings])

  return (
    <RowWithAlignment>
      {isSampleView ? (
        <img
          src={clientLogoPlaceholder}
          alt=''
          className='w-[130px] h-[45px] mb-2'
        />
      ) : hasClientLogo && clientlogo ? (
        <img
          src={clientlogo}
          alt=''
          className='w-[140px] h-[40px] rounded-md ml-4 my-2'
        />
      ) : (
        <div />
      )}

      {isSampleView && companyLogo.isChecked ? (
        companyLogo.value ? (
          <img
            src={companyLogo.value}
            alt=''
            className='w-[140px] h-[40px] rounded-md mr-4 my-2'
          />
        ) : (
          <img
            src={companyLogoPlaceholder}
            alt=''
            className='w-[140px] h-[40px] rounded-md mr-4 my-2'
          />
        )
      ) : isSampleView && !companyLogo.isChecked ? (
        <img
          src={companyLogoPlaceholder}
          alt=''
          className='w-[140px] h-[40px] rounded-md mr-4 my-2'
        />
      ) : companyLogo.isChecked && hasCompanyLogo && companyLogo.value ? (
        <img
          src={companyLogo.value}
          alt=''
          className='w-[140px] h-[40px] rounded-md mr-4 my-2'
        />
      ) : hasCompanyLogo && agency?.logoUrl ? (
        <div className='w-[42px] h-[42px] rounded-full overflow-hidden object-fill mr-4 my-2'>
          <img src={agency.logoUrl} alt='' />
        </div>
      ) : (
        <div />
      )}
    </RowWithAlignment>
  )
}
